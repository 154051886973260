import { RECOMMENDED_AD_CARD_THEME } from '@domains/ad/theme/[[[SITECODE]]]';
import styled from '@emotion/styled';
import { SIZE, WEIGHT } from '@lib/styles/partials/typography';

export const StyledLink = styled.a`
    display: block;
    color: inherit;
    text-decoration: none;
`;

export const ImageWrapper = styled.div`
    height: 115px;
    margin-bottom: 13px;
`;

export const StyledImage = styled.img`
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const DescriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const Paragraph = styled.p`
    margin: 0;
`;

export const PriceWrapper = styled(Paragraph)`
    color: ${RECOMMENDED_AD_CARD_THEME.price.color};
    font-size: ${SIZE.p1};
    font-weight: ${WEIGHT.bold};
`;

export const PricePerMeter = styled(Paragraph)`
    color: ${RECOMMENDED_AD_CARD_THEME.pricePerMetter.color};
    font-size: ${SIZE.p3};
    font-weight: ${WEIGHT.semibold};
`;

export const Title = styled(Paragraph)`
    color: ${RECOMMENDED_AD_CARD_THEME.title.color};
    font-size: ${SIZE.p4};
    word-break: break-all;
`;
