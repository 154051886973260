import { SITE_CONFIG } from '@config/siteConfig';
import { LocalLink } from '@domains/shared/components/LocalLink/LocalLink';
import { getPathname } from '@domains/shared/helpers/getPathname';
import { isValidHttpUrl } from '@domains/shared/helpers/isValidHttpUrl';
import { formatPrice } from '@domains/shared/helpers/price/formatPrice';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { Price } from '@type/pricing/price';
import type { JSX } from 'react';

import { formatPricePerMeter } from './helpers/formatPricePerMeter';
import {
    DescriptionWrapper,
    ImageWrapper,
    PricePerMeter,
    PriceWrapper,
    StyledImage,
    StyledLink,
    Title,
} from './SimilarAdsGridCard.theme';

interface Props {
    imageUrl: string;
    price: Price | undefined;
    pricePerMeter: string | undefined;
    area: string | undefined;
    title: string;
    url: string;
}

const decorateLocalUrl = (url: string): string => (url.startsWith('/') ? url : `/${url}`);

export const SimilarAdsGridCard = ({ imageUrl, price, pricePerMeter, title, area, url }: Props): JSX.Element => {
    const [t] = useTranslations();
    const { lang } = useSiteSettings();

    const priceProvided = price?.currency && price?.value;
    const formattedPrice = priceProvided ? formatPrice({ price: price.value, currency: price.currency, lang }) : null;
    const formattedArea = `${area} ${SITE_CONFIG.defaultUnits.area}`;
    // @pwojciechowska-fran setting currency as undefined to use default site currency as price per square meter unit
    // this is a temporary solution after changes on BE will be introduced calculated value returned from BE should be displayed.
    const formattedPricePerMeter = priceProvided
        ? formatPricePerMeter({ price: pricePerMeter, currency: undefined, lang })
        : null;
    const finalUrl = isValidHttpUrl(url) ? getPathname(url) : decorateLocalUrl(url);

    return (
        <li>
            <LocalLink href={finalUrl} passHref>
                <StyledLink data-cy="similar-ad-link">
                    <ImageWrapper>{imageUrl ? <StyledImage src={imageUrl} alt={title} /> : null}</ImageWrapper>

                    <DescriptionWrapper>
                        <PriceWrapper>
                            {priceProvided ? `${formattedPrice}` : t('frontend.ad.user-ads.price-on-demand')}
                        </PriceWrapper>

                        {formattedPricePerMeter && area ? (
                            <PricePerMeter>{`${formattedArea}, ${formattedPricePerMeter}`}</PricePerMeter>
                        ) : null}

                        <Title>{title}</Title>
                    </DescriptionWrapper>
                </StyledLink>
            </LocalLink>
        </li>
    );
};
