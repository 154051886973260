import { SITE_CONFIG } from '@config/siteConfig';
import { formatPrice } from '@domains/shared/helpers/price/formatPrice';
import type { Locale } from '@lib/i18n/types/locale';

export const formatPricePerMeter = ({
    price,
    currency = SITE_CONFIG.defaultUnits.currency,
    lang,
}: {
    price: string | undefined;
    currency: string | undefined;
    lang: Locale;
}): string | null => {
    if (!price) {
        return null;
    }

    const formattedPrice = formatPrice({ price: Number(price), currency, lang });

    return `${formattedPrice}/${SITE_CONFIG.defaultUnits.area}`;
};
