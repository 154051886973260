import type { JSX } from 'react';

import { GridWrapper } from './SimilarAdsGridSection.theme';

export const SimilarAdsGridSection = <TItem,>({
    headline,
    items,
    renderItem,
    ...props
}: {
    headline: JSX.Element;
    items: TItem[];
    renderItem: (item: TItem) => JSX.Element;
}): JSX.Element => (
    <section {...props}>
        {headline}
        <GridWrapper>{items.map(renderItem)}</GridWrapper>
    </section>
);
