import { GREY_UPDATED } from '@lib/styles/partials/colors';

import type { RecommendedAdCardTheme, SimilarAdsSectionTheme } from './type';

// @FIXME - this variables should be deleted and nexus theme should be used instead
export const RECOMMENDED_AD_CARD_THEME_BASE: RecommendedAdCardTheme = {
    price: {
        color: GREY_UPDATED.x700,
    },
    pricePerMetter: {
        color: GREY_UPDATED.x600,
    },
    title: {
        color: GREY_UPDATED.x500,
    },
};

export const SIMILAR_ADS_SECTION_THEME_BASE: SimilarAdsSectionTheme = {
    tooltipIcon: {
        color: GREY_UPDATED.x500,
    },
};
