import { AdSectionHeading } from '@domains/ad/components/AdSectionHeading/AdSectionHeading';
import { SimilarAdsGridCard } from '@domains/ad/components/SimilarAdsGridSection/components/SimilarAdsGridCard/SimilarAdsGridCard';
import { SimilarAdsGridSection } from '@domains/ad/components/SimilarAdsGridSection/SimilarAdsGridSection';
import { getAgencyUrl, hasSellerId } from '@domains/ad/helpers/getAgencyUrl';
import { getFormattedAgencyName } from '@domains/ad/helpers/getFormattedAgencyName';
import { LocalLink } from '@domains/shared/components/LocalLink/LocalLink';
import { useIntersection } from '@domains/shared/hooks/useIntersection/useIntersection';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import type { Agency } from '@type/ad/advertAgency';
import type { AdvertCategory } from '@type/ad/attributes/advertCategory';
import type { JSX, PropsWithChildren } from 'react';
import { useCallback, useRef } from 'react';

import { OwnerLink } from './UserAdsSection.theme';

interface Advert {
    adId: string;
    url: string;
    image: string;
    pricePerM: number;
    price: {
        value: string;
        unit: string;
    };
    netArea: string;
    title: string;
}

interface Props {
    adverts: Advert[];
    agency: Pick<Agency, 'id' | 'name' | 'url' | 'type'> | undefined;
    adCategory: AdvertCategory;
    hasBorderlessTitle?: boolean;
}

export const UserAdsSection = ({ adverts, agency, adCategory, hasBorderlessTitle }: Props): JSX.Element => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();
    const { lang } = useSiteSettings();
    const headerRef = useRef<HTMLDivElement>(null);
    const agencyUrl = getAgencyUrl({
        agency,
        estate: adCategory.name,
        transaction: adCategory.type,
        lang,
    });
    const agencyName = getFormattedAgencyName(agency?.name);

    const handleAuthorLinkClick = useCallback(() => {
        trackEvent('ads_from_seller');
    }, [trackEvent]);

    return (
        <SimilarAdsGridSection
            headline={
                <AdSectionHeading isBorderless={hasBorderlessTitle} ref={headerRef}>
                    {`${t('frontend.ad.user-ads.more-ads-from')} `}
                    {agency ? (
                        <LocalLink href={agencyUrl || '#'} passHref={!agencyUrl || !hasSellerId(agencyUrl)}>
                            <OwnerLink
                                as={agencyUrl && hasSellerId(agencyUrl) ? 'button' : undefined}
                                onClick={handleAuthorLinkClick}
                            >
                                {agencyName}
                            </OwnerLink>
                        </LocalLink>
                    ) : (
                        ` ${t('frontend.ad.user-ads.this-author')}`
                    )}
                </AdSectionHeading>
            }
            items={adverts}
            renderItem={(item): JSX.Element => {
                const isPriceProvided = item.price.unit && item.price.value;

                const price = isPriceProvided
                    ? { currency: item.price.unit, value: Number(item.price.value) }
                    : undefined;

                return (
                    <ConnectedRecommendedAdCard key={item.adId} advertId={item.adId} businessType={agency?.type}>
                        <SimilarAdsGridCard
                            imageUrl={item.image}
                            price={price}
                            pricePerMeter={item.pricePerM.toString()}
                            area={item.netArea}
                            title={item.title}
                            url={item.url}
                        />
                    </ConnectedRecommendedAdCard>
                );
            }}
        />
    );
};
export default UserAdsSection;

const ConnectedRecommendedAdCard = ({
    children,
    advertId,
    businessType,
}: PropsWithChildren<{ advertId: string; businessType: string | undefined }>): JSX.Element => {
    const { trackEvent } = useTracking();
    const wrapperRef = useRef<HTMLDivElement>(null);
    const isImpressionTrackedRef = useRef(false);
    const posterType = businessType?.toLocaleLowerCase() ?? 'private';

    const handleCardClick = useCallback(
        (newWindow?: boolean): void => {
            const touchPointButton = newWindow ? 'more_from_seller_new_tab' : 'more_from_seller';

            trackEvent('ad_click', {
                touch_point_button: touchPointButton,
                poster_type: posterType,
                ad_id: advertId,
            });
        },
        [advertId, posterType, trackEvent],
    );

    const intersectionCallback = useCallback(
        (_?: IntersectionObserverEntry, observer?: IntersectionObserver) => {
            if (!isImpressionTrackedRef.current) {
                trackEvent('ad_impressions', {
                    touch_point_button: 'more_from_seller',
                    poster_type: posterType,
                    ad_id: advertId,
                });

                isImpressionTrackedRef.current = true;
            }

            if (!wrapperRef.current) {
                return;
            }
            observer?.unobserve(wrapperRef.current);
            observer?.disconnect();
        },
        [trackEvent, posterType, advertId],
    );

    useIntersection(wrapperRef, intersectionCallback);

    return (
        <div onClick={(): void => handleCardClick()} onContextMenu={(): void => handleCardClick(true)} ref={wrapperRef}>
            {children}
        </div>
    );
};
