import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';

export const GridWrapper = styled.ul`
    display: grid;
    grid-gap: 32px;
    grid-template-columns: repeat(2, 1fr);
    margin: 0;
    padding: 0;
    list-style-type: none;

    @media (min-width: ${BREAKPOINT.sm}) {
        grid-template-columns: repeat(4, 1fr);
    }
`;
