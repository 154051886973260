import styled from '@emotion/styled';

export const OwnerLink = styled.a`
    border: 0;
    background: none;
    color: ${({ theme }): string => theme.deprecated.domains.ad.adUserAds.color};
    font-size: inherit;
    font-weight: inherit;
    text-decoration: none;
    cursor: pointer;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
`;
